//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * Page Landing
 */
// eslint-disable-next-line import/no-named-as-default
import Swiper, { Navigation, Pagination } from 'swiper'
import NavbarLanding from '@/components/navbar/landing.vue'
import 'swiper/swiper.scss'
import ChevronRightIcon from '@/components/icons/icon-chevron-right'
import ChevronLeftIcon from '@/components/icons/icon-chevron-left'

Swiper.use([Navigation, Pagination])

export const { BASE_URL } = process.env
export default {
  name: 'Index',
  components: {
    NavbarLanding,
    ChevronRightIcon,
    ChevronLeftIcon,
  },
  layout: 'landing',
  data() {
    return {
      selectedIdx: 0,
      isCredistore: this.$config.APP_NAME === 'credistore',
      swiper: null,
    }
  },
  head() {
    return {
      title: `${this.$config.BRAND_NAME} - ${this.$config.BRAND_TAGLINE}`,
      link: [
        {
          rel: 'canonical',
          href: BASE_URL,
        },
        {
          ogImage: `${BASE_URL}/credimart.png`,
          alt: this.metaDescription,
          type: 'image/png',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${BASE_URL}/credimart.png`,
        },
      ],
    }
  },
  computed: {
    features() {
      switch (this.$config.APP_NAME) {
        case 'credistore':
          return [
            {
              title: 'Mulai Berjualan Online dengan Mudah',
              image: '/img/credistore/feature-online.png',
              description:
                'Buat toko online/online shop bebas ribet! Cukup daftar dengan nomor handphone, kamu langsung bisa jualan online. GRATIS! Bebas biaya komisi atau biaya-biaya lainnya.',
            },
            {
              title: 'Pasarkan Produk Lebih Efektif',
              image: '/img/credistore/feature-share.png',
              description:
                'Promosikan jualan Kamu ke lebih banyak calon pembeli potensial dengan membagikan link produk kepada daftar kontak di handphone Kamu melalui WhatsApp dan media sosial.',
            },
            {
              title: 'Praktis Pantau dan Kelola Penjualan',
              image: '/img/credistore/feature-monitor.png',
              description:
                'Ketahui jumlah pengunjung, pesanan masuk, produk terjual, hingga pendapatan toko online/online shop kamu lewat satu Dashboard.',
            },
          ]
        default:
          // Credimart
          return [
            {
              title: 'Pembayaran Termin Hingga H+7',
              image: '/img/credimart/feature-tempo.png',
              description:
                'Nikmati beragam kemudahan pembayaran saat belanja di CrediMart. Untuk pelanggan yang telah memenuhi syarat, pembayaran bisa dilakukan hingga termin H+7.',
            },
            {
              title: 'Belanja Grosir Online',
              image: '/img/credimart/feature-product.png',
              description:
                'Cukup pesan barang belanjaan lewat smartphone, tanpa harus datang ke toko grosir.',
            },
            {
              title: 'Pesanan diantar ke tempat',
              image: '/img/credimart/feature-delivery.png',
              description:
                'CrediMart akan mengantarkan barang belanjaan ke lokasi pembeli.',
            },
          ]
      }
    },
  },
  mounted() {
    if (!!window && !!window.location) {
      window.location.href = `${this.$config.URL_CREDIBOOK}/${this.$config.APP_NAME}`;
    }
    // eslint-disable-next-line no-new
    const swiper = new Swiper('.swiper-container', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 50,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
    swiper.on('slideChange', (e) => {
      this.selectedIdx =
        e.activeIndex > this.features?.length ? 0 : e.activeIndex - 1
    })
  },
  methods: {
    handleFeatureClick(idx) {
      this.selectedIdx = idx
    },
    onSwiper(swiper) {
      this.swiper = swiper
    },
  },
}
